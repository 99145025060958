import HomePage from "./pages/home/HomePage";



function App() {
  return (
   <>
    <HomePage/>
   </>
    
  );
}

export default App;
